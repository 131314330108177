import React from "react";

interface HomeMissionProps {
  index: number;
  title: string;
  subtitle: string;
}

const HomeMission = ({ index, title, subtitle }: HomeMissionProps) => {
  return (
    <div key={index} className="mt-4">
      <div className="text-primary font-bold text-[70px]">
        {index + 1}
        <span className="text-secondary ml-2">.</span>
      </div>
      <h4 className="text-primary2 font-bold">{title}</h4>
      <div className="text-text text-p16 md:text-p18 xl:text-p20 mt-2">
        {subtitle}
      </div>
    </div>
  );
};

export default HomeMission;
