import { IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface ContentTitleWhiteProps {
  icon: string;
  title: string;
  subtitle: string;
}

const ContentTitleWhite = ({
  icon,
  title,
  subtitle,
}: ContentTitleWhiteProps) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="bg-secondary relative w-10 h-10 rounded-full">
        <FontAwesomeIcon
          icon={["fas", icon.replace("fa-", "") as IconName]}
          style={{ color: "#252271" }}
          className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]"
        />
      </div>
      <h2 className="text-white font-bold text-center mt-3">{title}</h2>
      <div className="text-white text-p16 md:text-p18 xl:text-p20 text-center mt-2">
        {subtitle}
      </div>
    </div>
  );
};

export default ContentTitleWhite;
