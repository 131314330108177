import { IconName } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface ContentTitleProps {
  icon: string;
  title: string;
  subtitle: string;
}

const ContentTitle = ({ icon, title, subtitle }: ContentTitleProps) => {
  return (
    <div className="flex flex-col items-center justify-center">
      {icon && (
        <div className="bg-secondary relative w-10 h-10 rounded-full">
          <FontAwesomeIcon
            icon={["fas", icon.replace("fa-", "") as IconName]}
            style={{ color: "#252271" }}
            className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%]"
          />
        </div>
      )}
      <h1 className="text-primary2 font-bold text-center mt-3 text-h5 lg:text-h2 xl:text-h1">
        {title}
      </h1>
      <div className="text-text text-p16 md:text-p18 xl:text-p20 text-center mt-2">
        {subtitle}
      </div>
    </div>
  );
};

export default ContentTitle;
