import { render } from "storyblok-rich-text-react-renderer";

const Article = ({ blok }: any) => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container mx-auto flex px-5 my-10 items-center justify-center flex-col">
        <h1 className="mb-4 text-primary text-center font-semibold">
          {blok.title}
        </h1>
        <img
          className="mb-10 w-1/2 h-1/2"
          alt={blok.image.alt}
          src={blok.image.filename}
        />
        <div className="text-center lg:w-2/3 w-full">
          <h2 className="text-gray-600">{blok.subtitle}</h2>
          <div className="mb-8 leading-relaxed text-justify">
            {render(blok.content)}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Article;
