import React, { useState } from "react";
import ContentTitle from "../components/ContentTitle";
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from "@storyblok/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

const Contact = ({ blok }: any) => {
  const [contactAnswers, setContactAnswers] = useState<any>([]);

  const onAnswerChange = (question: string, answer: string) => {
    let newContactAnswers = [];
    if (contactAnswers.find((ca: any) => ca.question === question)) {
      newContactAnswers = contactAnswers.map((ca: any) => {
        if (ca.question === question) {
          return { question, answer };
        } else return ca;
      });
      setContactAnswers(newContactAnswers);
    } else {
      setContactAnswers([...contactAnswers, { question, answer }]);
    }
  };

  const onSend = async () => {
    emailjs
      .send(
        process.env.REACT_APP_EMAIL_JS_SERVICE_ID ?? "",
        process.env.REACT_APP_EMAIL_JS_TEMPLATE_ID ?? "",
        {
          email: `
            <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-16 gap-y-8 px-8 md:px-[5%] py-10 mt-16 mb-10 bg-primary2">
              ${contactAnswers.map(
                (e: any) =>
                  `<div>
                  <h4 className="text-primary2 font-semibold mb-8">
                    ${e.question}
                  </h4>
                  <div className="text-p16 md:text-p18 xl:text-p20 font-medium text-primary2">
                    ${e.answer}
                  </div>
                </div>`
              )}
            </div>
          `,
        },
        process.env.REACT_APP_EMAIL_JS_PUBLIC_KEY ?? ""
      )
      .then(
        (result: any) => {
          toast.success(
            "Nous avons reçu votre mail. Pour des raisons envrionnementales vous ne recevrez pas d'email de confirmation.",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          console.log(result.text);
        },
        (error: any) => {
          toast.error(
            "Cela n'a pas fonctionné. Contactez nous directement à equipecompaire@gmail.com",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          console.log(error.text);
        }
      );
  };

  return (
    <div {...storyblokEditable(blok)}>
      <div className="mx-8 md:mx-[15%] xl:mx-[20%] mt-10">
        <ContentTitle
          icon={blok.title[0].icon.icon}
          title={blok.title[0].title}
          subtitle={blok.title[0].subtitle}
        />
      </div>
      <div className="bg-primary w-fit py-3 px-4 mx-auto my-5">
        <div className="flex flex-row items-center">
          <FontAwesomeIcon
            icon={["fas", "fa-envelope" as IconName]}
            style={{ color: "#FFF" }}
          />
          <a
            href={`mailto:${blok.email}`}
            className="ml-3 mt-1 text-white py-1 text-p18 group transition duration-300 w-fit text-center md:text-left"
          >
            {blok.email}
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a>
        </div>
        <div className="flex flex-row items-center">
          <FontAwesomeIcon
            icon={["fas", "fa-phone" as IconName]}
            style={{ color: "#FFF" }}
          />
          <a
            href={`tel:${blok.phone}`}
            className="ml-3 mt-1 text-white py-1 text-p18 group transition duration-300 w-fit text-center md:text-left"
          >
            {blok.phone}
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a>
        </div>
      </div>
      <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-16 gap-y-8 px-8 md:px-[5%] py-10 mt-16 mb-10 bg-primary2">
        {blok.questions.map((nestedBlok: SbBlokData, index: number) => {
          return (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
              onChange={onAnswerChange}
              index={index}
            />
          );
        })}
        <button
          className="bg-secondary text-primary2 rounded-lg w-fit h-fit px-8 py-4 my-4 block w-fit text-p12 md:text-p16 font-bold uppercase justify-self-end self-end"
          onClick={onSend}
        >
          Envoyer
        </button>
      </div>
    </div>
  );
};

export default Contact;
