import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from "@storyblok/react";
import ContentTitle from "../components/ContentTitle";

const Prices = ({ blok }: any) => {
  return (
    <div {...storyblokEditable(blok)}>
      <div className="mx-8 md:mx-[15%] xl:mx-[20%] mt-5">
        <ContentTitle
          icon={blok.priceTitle[0].icon.icon}
          title={blok.priceTitle[0].title}
          subtitle={blok.priceTitle[0].subtitle}
        />
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 mx-8 md:mx-[5%] xl:mx-[10%] mt-5 mb-10">
        {blok.prices.map((nestedBlok: SbBlokData) => {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />;
        })}
      </div>
    </div>
  );
};

export default Prices;
