import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";

const Header = ({ blok }: any) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const openMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.querySelector("#navbar-collapse")?.classList.toggle("hidden");
    document.querySelector("#navbar-collapse")?.classList.toggle("flex");
  };

  return (
    <nav
      className="w-full z-10 bg-white py-2 md:py-4 shadow-[0_8px_15px_-6px_rgba(0,0,0,0.1)] rounded-b-2xl sticky top-0"
      id="header"
    >
      <div className="px-8 md:flex md:items-center">
        <div className="flex justify-between items-center">
          <a href="/">
            <img src={blok.logo.filename} alt="logo" className="w-[80%]" />
          </a>
          <button
            className="px-3 py-1 rounded text-primary hover:opacity-75 md:hidden"
            id="navbar-toggle"
            onClick={openMenu}
          >
            {isMenuOpen ? (
              <FontAwesomeIcon icon={faXmark} />
            ) : (
              <FontAwesomeIcon icon={faBars} />
            )}
          </button>
        </div>

        <div
          className="hidden md:flex flex-col md:flex-row md:ml-auto mt-3 md:mt-0"
          id="navbar-collapse"
        >
          {blok.menu_list.map((menu: any, index: number) => (
            <a
              key={index}
              href={
                window.location.pathname === "/"
                  ? menu.menu_link.cached_url
                  : window.location.href.replace(
                      window.location.pathname,
                      "/" + menu.menu_link.cached_url
                    )
              }
              className={
                window.location.pathname === `/${menu.menu_link.cached_url}`
                  ? "text-primary font-semibold text-p18 xl:text-p20 p-2 lg:px-4 md:mx-2"
                  : "text-graySecondary text-p16 xl:text-p18 p-2 lg:px-4 md:mx-2"
              }
            >
              {menu.name}
            </a>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Header;
