import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from "@storyblok/react";
import Button from "../components/Button";
import ContentTitle from "../components/ContentTitle";
import HomeMission from "../components/HomeMission";
import Pilier from "../components/Pilier";
import ContentTitleWhite from "../components/ContentTitleWhite";

const Home = ({ blok }: any) => {
  return (
    <div {...storyblokEditable(blok)} className="min-h-[60vh]">
      <div className="relative xl:mt-[-10%]">
        <img src={blok.image.filename} alt="enfants" className="w-full" />
        <div className="absolute top-0 bottom-0 left-0 right-0 bg-black opacity-50" />
        <div className="text-white absolute left-0 right-0 top-0 bottom-0 m-auto my-4 mx-8 md:mx-[15%] flex flex-col justify-center">
          <h2 className="font-bold xl:text-h1">{blok.title}</h2>
          <div className="h-px w-full bg-secondary my-10 hidden md:block " />
          <h5 className="font-semibold hidden md:block">{blok.subtitle}</h5>
          <div className="hidden md:block mt-5">
            <Button
              text={blok.button[0].text}
              outlined={false}
              link={blok.button[0].link.cached_url}
            />
          </div>
        </div>
      </div>
      <div className="md:hidden mt-5 mx-6">
        <h5 className="font-semibold text-text">{blok.subtitle}</h5>
        <div className="flex items-left justify-left ">
          <Button
            text={blok.button[0].text}
            outlined={false}
            link={blok.button[0].link.cached_url}
          />
        </div>
      </div>
      <div className="mx-8 md:mx-[15%] xl:mx-[20%] mt-10">
        <ContentTitle
          icon={blok.mission[0].icon.icon}
          title={blok.mission[0].title}
          subtitle={blok.mission[0].subtitle}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-10 xl:gap-20 mx-8 md:mx-[5%] xl:mx-[10%] mt-5">
        {blok.missions.map((mission: any, index: number) => {
          return (
            <HomeMission
              index={index}
              title={mission.title}
              subtitle={mission.description}
            />
          );
        })}
      </div>
      <div className="flex justify-end mt-5 mx-8 md:mx-[5%] xl:mx-[10%]">
        <Button
          text={blok.missionButton[0].text}
          outlined={false}
          link={blok.missionButton[0].link.cached_url}
        />
      </div>
      <div className="mx-8 md:mx-[15%] xl:mx-[20%] mt-10">
        <ContentTitle
          icon={blok.piliersTitle[0].icon.icon}
          title={blok.piliersTitle[0].title}
          subtitle={blok.piliersTitle[0].subtitle}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-10 xl:gap-20 mx-8 md:mx-[5%] xl:mx-[10%] mt-5">
        {blok.piliers.map((pilier: any, index: number) => {
          return (
            <Pilier
              index={index}
              image={pilier.image.filename}
              title={pilier.title}
              subtitle={pilier.subtitle}
            />
          );
        })}
      </div>
      <div className="flex justify-end mt-5 mx-8 md:mx-[5%] xl:mx-[10%]">
        <Button
          text={blok.piliersButton[0].text}
          outlined={false}
          link={blok.piliersButton[0].link.cached_url}
        />
      </div>
      <div className="mx-8 md:mx-[15%] xl:mx-[20%] mt-10">
        <ContentTitle
          icon={blok.teamTitle[0].icon.icon}
          title={blok.teamTitle[0].title}
          subtitle={blok.teamTitle[0].subtitle}
        />
      </div>
      <div className="grid grid-cols-1 gap-8 mx-8 md:mx-[5%] xl:mx-[10%] mt-5 mb-10">
        {blok.teamList.map((nestedBlok: SbBlokData) => {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />;
        })}
      </div>
      <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-8 mx-8 md:mx-[5%] xl:mx-[10%] mt-5 mb-10">
        {blok.mentorsList.map((nestedBlok: SbBlokData) => {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />;
        })}
      </div>
      <div className="flex justify-end mt-5 mx-8 md:mx-[5%] xl:mx-[10%]">
        <Button
          text={blok.mentorsButton[0].text}
          outlined={false}
          link={blok.mentorsButton[0].link.cached_url}
        />
      </div>
      <div className="mx-8 md:mx-[15%] xl:mx-[20%] mt-10">
        <ContentTitle
          icon={blok.pricesTitle[0].icon.icon}
          title={blok.pricesTitle[0].title}
          subtitle={blok.pricesTitle[0].subtitle}
        />
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 mx-8 md:mx-[5%] xl:mx-[10%] mt-5 mb-10">
        {blok.prices.map((nestedBlok: SbBlokData) => {
          return <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />;
        })}
      </div>
      <div className="flex justify-end mt-5 mx-8 md:mx-[5%] xl:mx-[10%]">
        <Button
          text={blok.pricesButton[0].text}
          outlined={false}
          link={blok.pricesButton[0].link.cached_url}
        />
      </div>
      <div className="mx-8 md:mx-[15%] xl:mx-[20%] mt-10">
        <ContentTitle
          icon={blok.studentsTitle[0].icon.icon}
          title={blok.studentsTitle[0].title}
          subtitle={blok.studentsTitle[0].subtitle}
        />
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-10 xl:gap-20 mx-8 md:mx-[5%] xl:mx-[10%] mt-5">
        {blok.studentsSelection.map((item: any, index: number) => {
          return (
            <HomeMission
              index={index}
              title={item.title}
              subtitle={item.description}
            />
          );
        })}
      </div>
      <div className="flex justify-end mt-5 mx-8 md:mx-[5%] xl:mx-[10%]">
        <Button
          text={blok.studentsButton[0].text}
          outlined={false}
          link={blok.studentsButton[0].link.cached_url}
        />
      </div>
      <div className="bg-primary py-10 mt-10">
        <div className="mx-8 md:mx-[15%] xl:mx-[20%] mt-10">
          <ContentTitleWhite
            icon={blok.reviewsTitle[0].icon.icon}
            title={blok.reviewsTitle[0].title}
            subtitle={blok.reviewsTitle[0].subtitle}
          />
        </div>
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-8 mx-8 md:mx-[5%] xl:mx-[10%] mt-5">
          {blok.reviews.map((nestedBlok: SbBlokData) => {
            return (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            );
          })}
        </div>
      </div>
      <div className="py-10 mb-10">
        <div className="mx-8 md:mx-[15%] xl:mx-[20%] mt-10">
          <ContentTitle
            icon={blok.articlesTitle[0].icon.icon}
            title={blok.articlesTitle[0].title}
            subtitle={blok.articlesTitle[0].subtitle}
          />
        </div>
        <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-8 mx-8 md:mx-[5%] xl:mx-[10%] mt-5">
          {blok.articles.map((nestedBlok: SbBlokData) => {
            return (
              <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;
