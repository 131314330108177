import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { render } from "storyblok-rich-text-react-renderer";

const Mission = ({ mission, index }: any) => {
  return (
    <div key={index} className="mt-10">
      <div className="flex flex-row justify-center">
        <FontAwesomeIcon
          icon={["fas", mission.image.icon.replace("fa-", "")]}
          size="6x"
          style={{ color: "#4841cd" }}
        />
      </div>
      <div className="flex justify-center items-center">
        <h3 className="w-fit mt-2 text-primary2 font-semibold after:m-auto after:block after:content-[''] after:h-[10px] after:w-[50%] after:bg-secondary after:rounded">
          {mission.title}
        </h3>
      </div>
      <p className="mt-8 text-lightPrimary text-justify text-p16 md:text-p20">
        {render(mission.text)}
      </p>
    </div>
  );
};

export default Mission;
