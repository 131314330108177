import {
  faFacebookSquare,
  faInstagram,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Footer = () => {
  return (
    <div className="bg-primary px-10 py-8" id="footer">
      <div className="flex flex-col md:flex-row justify-evenly">
        <div className="hidden md:flex flex-col">
          <div className="text-secondary text-h5 font-semibold mb-4">
            A propos
          </div>
          <a
            href={
              window.location.pathname === "/"
                ? "/mission"
                : window.location.href.replace(
                    window.location.pathname,
                    "/mission"
                  )
            }
            className="text-white py-1 text-p18 group transition duration-300 w-fit"
          >
            Notre mission
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a>
          <a
            href={
              window.location.pathname === "/"
                ? "/histoire"
                : window.location.href.replace(
                    window.location.pathname,
                    "/histoire"
                  )
            }
            className="text-white py-1 text-p18 group transition duration-300 w-fit"
          >
            Notre Histoire
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a>
          {/* <a
            href={
              window.location.pathname === "/"
                ? "/mentors"
                : window.location.href.replace(
                    window.location.pathname,
                    "/mentors"
                  )
            }
            className="text-white py-1 text-p18 group transition duration-300 w-fit"
          >
            Nos Mentors
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a> */}
          <a
            href={
              window.location.pathname === "/"
                ? "/ateliers"
                : window.location.href.replace(
                    window.location.pathname,
                    "/ateliers"
                  )
            }
            className="text-white py-1 text-p18 group transition duration-300 w-fit"
          >
            Ateliers
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a>
          <a
            href={
              window.location.pathname === "/"
                ? "/tarifs"
                : window.location.href.replace(
                    window.location.pathname,
                    "/tarifs"
                  )
            }
            className="text-white py-1 text-p18 group transition duration-300 w-fit"
          >
            Tarifs
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a>
        </div>
        <div className="hidden md:flex flex-col">
          <div className="text-secondary text-h5 font-semibold mb-4">
            Assitance
          </div>
          <a
            href={
              window.location.pathname === "/"
                ? "/contact"
                : window.location.href.replace(
                    window.location.pathname,
                    "/contact"
                  )
            }
            className="text-white py-1 text-p18 group transition duration-300 w-fit"
          >
            Contact
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a>
          <a
            href={
              window.location.pathname === "/"
                ? "/blog"
                : window.location.href.replace(
                    window.location.pathname,
                    "/blog"
                  )
            }
            className="text-white py-1 text-p18 group transition duration-300 w-fit"
          >
            Blog
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a>
          <a
            href={
              window.location.pathname === "/"
                ? "/faq"
                : window.location.href.replace(window.location.pathname, "/faq")
            }
            className="text-white py-1 text-p18 group transition duration-300 w-fit"
          >
            FAQ
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a>
        </div>
        <div className="flex flex-col items-center md:items-start">
          <div className="text-secondary text-h5 font-semibold mb-4 text-center md:text-left">
            Contactez nous
          </div>
          <a
            href="mailto:equipecompaire@gmail.com"
            className="text-white py-1 text-p18 group transition duration-300 w-fit text-center md:text-left"
          >
            equipecompaire@gmail.com
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a>
          <a
            href="tel:+33645618450"
            className="text-white py-1 text-p18 group transition duration-300 w-fit text-center md:text-left"
          >
            +33645618450
            <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
          </a>
        </div>
        <div className="flex flex-col mt-3 md:mt-0">
          <div className="text-secondary text-h5 font-semibold mb-5 text-center md:text-left">
            Suivez-nous
          </div>
          <div className="flex flex-row items-center justify-center md:justify-between">
            <a
              href="https://www.facebook.com/EquipeCompaire/"
              className="text-white mr-5 md:mr-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebookSquare} size="2xl" />
            </a>
            <a
              href="https://www.instagram.com/compaire_paris/"
              className="text-white mr-5 md:mr-0"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} size="2xl" />
            </a>
            <a
              href="https://www.linkedin.com/company/compaire/"
              className="text-white"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2xl" />
            </a>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-5">
        <a
          href="https://a.storyblok.com/f/226835/x/53a14105c3/cgs-compaire.pdf"
          className="text-white text-p18 group transition duration-300 w-fit text-center"
          target="_blank"
          rel="noopener noreferrer"
        >
          CGS
          <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
        </a>
        <a
          href="https://a.storyblok.com/f/226835/x/66a3e79cdd/privacy-policy-compaire.pdf"
          className="text-white text-p18 group transition duration-300 w-fit text-center ml-3"
          target="_blank"
          rel="noopener noreferrer"
        >
          | Politique de confidentialité
          <span className="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-white" />
        </a>
        <div className="text-white ml-3">
          |<span className="ml-3">© 2023 Compaire</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
