import { storyblokEditable } from "@storyblok/react";
import Mission from "../components/Mission";

const OurMission = ({ blok }: any) => {
  return (
    <div {...storyblokEditable(blok)}>
      <div className="my-10 mx-[15%] text-center min-h-screen">
        <h1 className="text-primary2 font-bold">{blok.title}</h1>
        <div className="mt-5">
          {blok.missions.map((mission: any, index: number) => {
            return <Mission mission={mission} index={index} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default OurMission;
