import { storyblokEditable } from "@storyblok/react";
import { render } from "storyblok-rich-text-react-renderer";

const Block = ({ blok }: any) => {
  return (
    <div
      {...storyblokEditable(blok)}
      className="rounded-2xl flex flex-col items-center bg-primary py-10 px-4 border border-grayBorderSecondary border-solid"
    >
      <img
        src={blok.image.filename}
        className="w-32 rounded-full"
        alt="Avatar"
      />
      <h3 className="text-white text-center pb-2 mt-3 font-semibold">
        {blok.title}
      </h3>
      <div className=" text-white leading-relaxed px-5 py-3">
        {render(blok.description)}
      </div>
    </div>
  );
};

export default Block;
