import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { storyblokEditable } from "@storyblok/react";
import { useEffect, useState } from "react";

const Faqs = ({ blok }: any) => {
  const [isQuestionsOpen, setIsQuestionsOpen] = useState<boolean[]>([]);

  useEffect(() => {
    const newIsQuestionsOpen: boolean[] = [];
    [...Array(blok.faqList.length)].map((_) => newIsQuestionsOpen.push(false));
    setIsQuestionsOpen([...newIsQuestionsOpen]);
  }, [blok.faqList.length]);

  const onQuestionClick = (index: number) => {
    let newIsQuestionsOpen: boolean[] = [...isQuestionsOpen];
    newIsQuestionsOpen[index] = !newIsQuestionsOpen[index];
    setIsQuestionsOpen([...newIsQuestionsOpen]);
    if (isQuestionsOpen[index]) {
      document
        .querySelector(`#subtitle-${index}`)
        ?.classList.replace("max-h-unset", "max-h-0");
      document
        .querySelector(`#subtitle-${index}`)
        ?.classList.replace("mt-4", "mt-0");
    } else {
      document
        .querySelector(`#subtitle-${index}`)
        ?.classList.replace("max-h-0", "max-h-unset");
      document
        .querySelector(`#subtitle-${index}`)
        ?.classList.replace("mt-0", "mt-4");
    }
  };

  return (
    <div className="mx-[15%] my-8" {...storyblokEditable(blok)}>
      <div className="text-center text-h2 font-bold mb-10">{blok.title}</div>
      <div className="mt-2">
        {blok.faqList.map((nestedBlok: any, index: number) => {
          return (
            <div
              className="border-b border-lightgray-300 pb-4 mb-4"
              key={index}
            >
              <button onClick={() => onQuestionClick(index)} className="w-full">
                <div className="flex justify-between items-center">
                  <div className="text-h4 font-semi-bold">
                    {nestedBlok.title}
                  </div>
                  <div className="p-4 rounded-full text-primary hover:opacity-75 bg-[#EBEBEB]">
                    {isQuestionsOpen[index] ? (
                      <FontAwesomeIcon
                        icon={faChevronUp}
                        className="w-[20px] h-[20px] block"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="w-[20px] h-[20px] block"
                      />
                    )}
                  </div>
                </div>
              </button>
              <div
                className="max-h-0 mt-0 overflow-hidden duration-500 ease-in transition-[max-height] delay-0"
                id={`subtitle-${index}`}
              >
                <div className="text-p18 mb-2">{nestedBlok.subtitle}</div>
                <div className="flex justify-end">
                  <a
                    className="rounded-lg bg-primary text-white px-3 py-2 my-4 block w-fit text-p20"
                    href={nestedBlok.button[0].menu_link.cached_url}
                  >
                    {nestedBlok.button[0].name}
                  </a>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faqs;
