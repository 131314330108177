import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { apiPlugin, storyblokInit } from "@storyblok/react";
import Faqs from "./pages/Faqs";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import OurMentors from "./pages/OurMentors";
import OurMission from "./pages/OurMission";
import OurStory from "./pages/OurStory";
import Prices from "./pages/Prices";
import Home from "./pages/Home";
import Article from "./components/Article";
import Block from "./components/Block";
import Review from "./components/Review";
import Price from "./components/Price";
import Ateliers from "./pages/Ateliers";
import { CookiesProvider } from "react-cookie";
import ContactQuestion from "./components/ContactQuestion";

storyblokInit({
  accessToken: process.env.REACT_APP_STORYBLOK_KEY,
  use: [apiPlugin],
  components: {
    faqs: Faqs,
    blog: Blog,
    contact: Contact,
    ourMentors: OurMentors,
    ourMission: OurMission,
    ourStory: OurStory,
    prices: Prices,
    home: Home,
    article: Article,
    block: Block,
    review: Review,
    price: Price,
    ateliers: Ateliers,
    contactQuestion: ContactQuestion,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
