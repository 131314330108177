import React from "react";

interface PilierProps {
  index: number;
  image: string;
  title: string;
  subtitle: string;
}

const Pilier = ({ index, image, title, subtitle }: PilierProps) => {
  return (
    <div key={index} className="mt-4">
      <img src={image} alt={title} />
      <h4 className="text-primary2 font-bold mt-4">{title}</h4>
      <div className="text-text text-p16 md:text-p18 xl:text-p20 mt-2">
        {subtitle}
      </div>
    </div>
  );
};

export default Pilier;
