import { useCookies } from "react-cookie";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["localConsent"]);

  const acceptCookie = () => {
    setCookie("localConsent", "true", {});
  };

  if (cookies.localConsent) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 flex flex-col md:flex-row items-center justify-center md:justify-between items-center px-4 md:px-16 py-4 md:py-8 bg-primary z-10">
      <span className="text-white text-base mb-2 md:mb-0 md:mr-16 text-center md:text-left text-p14 md:text-p16">
        Ce site utilise des cookies pour améliorer l'expérience utilisateur. En
        utilisant notre site Web, vous consentez à tous les cookies conformément
        à notre politique en matière de cookies.
      </span>
      <button
        className="bg-white py-2 px-8 rounded text-primary font-semibold z-10"
        onClick={() => acceptCookie()}
      >
        Accepter
      </button>
    </div>
  );
};

export default CookieConsent;
