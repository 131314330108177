import { storyblokEditable } from "@storyblok/react";
import Button from "./Button";

const Review = ({ blok }: any) => {
  return (
    <div
      className="shadow-lg p-4 md:p-8 rounded-lg mx-6 md:mx-0 bg-white flex flex-col justify-between"
      {...storyblokEditable(blok)}
    >
      <div>
        {blok.isArticle && (
          <div className="mb-4">
            <img src={blok.image.filename} alt={blok.image.alt} />
          </div>
        )}
        <h5 className="font-semibold text-p20 md:text-p22 lg:text-h5">
          {blok.title}
        </h5>
        <p className="text-p14 md:text-p16 text-graySecondary">
          {blok.subtitle}
        </p>
        <p className=" text-p16 md:text-p18 lg:text-p20 text-gray overflow-y-auto pr-2">
          {blok.review}
        </p>
      </div>
      {blok.isArticle && (
        <Button
          text={blok.button[0].text}
          outlined={false}
          link={blok.button[0].link.cached_url}
          newWindow
        />
      )}
    </div>
  );
};

export default Review;
