import { storyblokEditable } from "@storyblok/react";
import { render } from "storyblok-rich-text-react-renderer";

const Price = ({ blok }: any) => {
  return (
    <div
      className="shadow-lg rounded-lg bg-white flex flex-col justify-between"
      {...storyblokEditable(blok)}
    >
      <div>
        <div className="relative">
          <img src={blok.image.filename} alt={blok.image.alt} />
          <div className="bg-white absolute bottom-0 left-0 px-5 py-1">
            <h5 className="font-semibold text-p20 md:text-p22 lg:text-h5">
              {blok.title}
            </h5>
            <p className="text-p14 md:text-p16 text-graySecondary">
              {blok.subtitle}
            </p>
          </div>
        </div>
        <div className="mb-8 leading-relaxed text-justify px-5 py-3">
          {render(blok.content)}
        </div>
      </div>
    </div>
  );
};

export default Price;
