import { storyblokEditable } from "@storyblok/react";
import React from "react";

const ContactQuestion = ({ blok, onChange, index }: any) => {
  return (
    <div className="bg-white rounded-lg px-8 py-8" {...storyblokEditable(blok)}>
      <h4 className="text-primary2 font-semibold mb-8">
        {blok.Title}{" "}
        {blok.isRequired && <span className="text-red-600">*</span>}
      </h4>
      {blok.isRadio &&
        blok.radioList.map((nestedBlok: any, i: number) => {
          return (
            <div className="flex items-center mb-4" key={i}>
              <input
                id={`radio-${i}`}
                type="radio"
                name={`radio-${index}`}
                onChange={() => onChange(blok.Title, nestedBlok.text)}
                className="w-6 h-6 text-secondary bg-gray-100 border-gray-300 focus:ring-none focus:ring-0 checked:bg-emerald-400 checked:bg-primary checked:hover:bg-primary checked:active:bg-primary checked:focus:bg-primary focus:bg-primary focus:outline-none focus:ring-1 focus:ring-primary"
              />
              <label
                htmlFor={`radio-${i}`}
                className="ml-2 text-p16 md:text-p18 xl:text-p20 font-medium text-primary2"
              >
                {nestedBlok.text}
              </label>
              {nestedBlok.isOther && (
                <input
                  type="text"
                  onChange={(event) =>
                    onChange(
                      blok.Title,
                      nestedBlok.text + " " + event.target.value
                    )
                  }
                  className="ml-4 border-b-2 text-primary2 focus:border-primary w-3/4 text-p20 focus-visible:outline-none"
                />
              )}
            </div>
          );
        })}
      {!blok.isRadio && !blok.isTextArea && (
        <input
          type="text"
          onChange={(event) => onChange(blok.Title, event.target.value)}
          className="border-b-2 text-primary2 focus:border-primary w-full text-p20 focus-visible:outline-none"
          placeholder="Votre réponse"
        />
      )}
      {!blok.isRadio && blok.isTextArea && (
        <textarea
          onChange={(event) => onChange(blok.Title, event.target.value)}
          className="border-2 text-primary2 h-[200px] focus:border-primary w-full text-p20 focus-visible:outline-none p-2"
          placeholder="Votre réponse"
        />
      )}
    </div>
  );
};

export default ContactQuestion;
