interface ButtonProps {
  text: string;
  outlined: boolean;
  link: string;
  newWindow?: boolean;
}

const Button = ({ text, outlined, link, newWindow = false }: ButtonProps) => {
  return (
    <a
      className={
        outlined
          ? "border border-primary text-primary rounded-lg px-3 py-2 my-4 block w-fit text-p12 md:text-p16 font-medium"
          : "bg-primary text-white rounded-lg bg-primary text-white px-8 py-4 my-4 block w-fit text-p12 md:text-p16 font-bold uppercase"
      }
      href={link}
      rel={newWindow ? "noopener noreferrer" : ""}
      target={newWindow ? "_blank" : ""}
    >
      {text}
    </a>
  );
};

export default Button;
